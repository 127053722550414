import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "gatsby";
import styled from "styled-components";
import lottie from "lottie-web";
import { useForm } from "react-hook-form";

import Layout from "../components/layout";
import bluePencil from "../images/icons/yellow-pencil.svg";
import smileAnimationData from "../lottie/schmiley-jason.json";
import SEO from "../components/seo";
import tagOptions, { defaultTagValues } from "../tags";
import Tag from "../components/tag";

const IndexPage = () => {
  const [leftProps, setLeft] = useSpring(() => ({
    width: "100vw",
    height: "100vh",
    config: {
      tension: 280,
      friction: 45,
    },
  }));

  const [linkProps, setLink] = useSpring(() => ({
    opacity: 0,
  }));

  const [rightProps, setRight] = useSpring(() => ({
    width:
      typeof window !== "undefined" && window.innerWidth < 800
        ? "100vw"
        : "0vw",
    height:
      typeof window !== "undefined" && window.innerWidth < 800
        ? "0vh"
        : "100vh",
    opacity: 0,
    config: {
      tension: 280,
      friction: 45,
    },
  }));

  const setMobile = () => {
    setRight({ height: "100vh", width: "100vw", opacity: 1 });
    setLeft({ width: "100vw", height: "70vh" });
  };

  const setDesktop = () => {
    setRight({ height: "100vh", width: "50vw", opacity: 1 });
    setLeft({ width: "50vw", height: "100vh" });
  };

  useEffect(() => {
    const adjustWindow = () => {
      if (
        smileAnimation.currentFrame === 151 &&
        typeof window !== "undefined" &&
        window?.innerWidth < 800
      ) {
        setMobile();
      } else if (
        smileAnimation.currentFrame === 151 &&
        window &&
        typeof window !== "undefined" &&
        window?.innerWidth > 800
      ) {
        setDesktop();
      }
    };

    window.addEventListener("resize", adjustWindow);
    const smileAnimation = lottie.loadAnimation({
      container: document.getElementById("animation"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: smileAnimationData,
    });

    smileAnimation.addEventListener("complete", function () {
      window?.innerWidth < 800 ? setMobile() : setDesktop();
      setLink({ opacity: 1 });
    });

    return () => {
      smileAnimation.destroy();
      window.removeEventListener("resize", adjustWindow);
    };
  }, []);

  const { register, watch, reset } = useForm();
  const values = watch();

  return (
    <Layout fullWidth hideNav>
      <SEO title="Home" />
      <Wrapper>
        <LeftContainer style={leftProps}>
          <div id="animation" />
          <BottomSection>
            <h1>Kirsty Ho</h1>
            <p className="lowercase">
              Hi, I'm an <strong>illustrator</strong> and{" "}
              <strong>designer</strong>
            </p>
            <LinkAnimation style={linkProps}>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </LinkAnimation>
          </BottomSection>
        </LeftContainer>
        <RightContainer style={rightProps}>
          <img src={bluePencil} />
          <BottomSection>
            <h1>I'm looking for...</h1>

            <TagContainer>
              {tagOptions.map((tag) => {
                return (
                  <StyledTag key={tag}>
                    <Tag {...{ register, tag }} fullWidth />
                  </StyledTag>
                );
              })}
              <EverythingBtn>
                <Link to="/work" state={values}>
                  Everything
                </Link>
              </EverythingBtn>
            </TagContainer>

            <LinkBtnContainer>
              <SearchBtn>
                <Link to="/work" state={values}>
                  Go
                </Link>
              </SearchBtn>
            </LinkBtnContainer>
          </BottomSection>
        </RightContainer>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

const LeftContainer = styled(animated.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme: { blue } }) => `background-color: ${blue}`};
  color: white;
  h1 {
    font-weight: bold;
  }
  a,
  p {
    color: #fff;
  }
  a {
    transition: 0.2s ease-in-out;
    &:hover {
      color: white;
      transition: 0.2s ease-in-out;
    }
  }
  strong {
    color: white;
  }
  #animation {
    height: 250px;
    width: 250px;
  }
`;

const RightContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: bold;
    ${({ theme: { blue } }) => `color: ${blue}`};
  }
  a {
    ${({ theme: { blue } }) => `color: ${blue}`};
    transition: 0.2s ease-in-out;
    &:hover {
      ${({ theme: { blue } }) => `color: ${blue}`};
      transition: 0.2s ease-in-out;
    }
  }
  img {
    box-sizing: border-box;
    height: 250px;
    width: 250px;
    padding: 4em;
    margin: 0;
  }
`;

const LinkAnimation = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  a {
    margin-bottom: 0.5em;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`;

const BottomSection = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .lowercase {
    text-transform: initial;
    margin-bottom: 0.6em;
  }
`;

const TagContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  min-width: 200px;
  overflow: hidden;
`;

const SearchBtn = styled.div`
  background-color: ${({ theme: { blue } }) => blue};
  border: 2px solid transparent;
  padding: 5px 12px;
  border-radius: 20px;
  margin-top: 2em;
  cursor: pointer;
  a {
    color: #fff;
    font-weight: normal;
  }
  a:hover {
    color: #fff;
  }
`;

const EverythingBtn = styled.div`
  border: 2px solid ${({ theme: { blue } }) => blue};
  padding: 5px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin: 4px;
  &:hover {
    background-color: ${({ theme: { blue } }) => blue};
  }
  a {
    color: ${({ theme: { blue } }) => blue};
    font-weight: normal;
    word-break: keep-all;
    word-wrap: normal;
    overflow: hidden;
  }
  a:hover {
    color: #fff;
  }
`;

const LinkBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTag = styled.div`
  width: 100%;
`;
